import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar.js';
import PrimaryButton from './ui/PrimaryButton.js';
import FooterToolbar from './ui/FooterToolbar.js';
import Input from './forms/Input.js';
import UserShoppingList from "../api/services/UserShoppingList.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CameraIcon } from '@heroicons/react/24/outline';

const ShoppingListCreate = () => {

  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  const [listName, setListName] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el submit

  const handleStore = async () => {
    if (isSubmitting) return; // Prevenir múltiples envíos si ya está en curso

    setIsSubmitting(true); // Deshabilitar el botón al empezar a procesar

    try {
      const body = new FormData();
      body.append('name', listName);
      body.append('image_file', document.querySelector('input[type="file"]').files[0] ?? null);
      body.append('shopping_list_type_id', 1);
      body.append('date', document.querySelector('[name="date"]').value);
      const response = await UserShoppingList.create(user.id, body);
      navigate('/shoppingLists', { replace: true });
    } catch (error) {
      alert(error.response?.data?.message ?? 'Error inesperado. Inténtalo de nuevo.');
      console.error('Error creating shopping list:', error);
    } finally {
      setIsSubmitting(false); // Volver a habilitar el botón tras el procesamiento
    }
  };

  const onChangeImageFile = e => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onResetImageFile = e => {
    setImageFile(null);
    setImageData(null);
  };

  const handleNameChange = (event) => {
    setListName(event.target.value);
  };

  return (
    <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3 mb-8">
          <Toolbar to='/login'>Crear una nueva lista</Toolbar>
          <div className="hidden">
            <Input id="image_file" onChange={onChangeImageFile} onReset={onResetImageFile} name="image_file" type="file" placeholder="" label={'Imagen principal'} />
          </div>
          <div className='text-center font-medium text-primary'>Foto de la lista</div>
          <div className='mb-4'>
            {imageFile ? (
              <div className="flex justify-center">
                <div className="relative flex justify-center items-center w-32 h-32">
                  <div className="flex justify-center items-center rounded w-32 h-32 bg-buddy-bg-4 overflow-hidden">
                    <img onClick={(e) => { document.querySelector('[name="image_file"]').click(); }} className="h-full w-full object-cover object-center" src={imageData} />
                  </div>
                  <button type='button' onClick={onResetImageFile} className='absolute -right-2 -top-2 flex items-center justify-center w-8'>
                    <div className='w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center'>
                      <FontAwesomeIcon className='text-white' icon={faTimes} />
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-center">
                <div className="flex justify-center items-center rounded w-32 h-32 bg-buddy-bg-4">
                  <button onClick={(e) => { document.querySelector('[name="image_file"]').value = null; document.querySelector('[name="image_file"]').click(); }} className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2">
                    <CameraIcon className="size-6" />
                    <span className="text-sm">Añadir foto</span>
                  </button>
                </div>
              </div>
            )}
          </div>

          <Input name="name" type="text" placeholder="" label={"Nombre de la lista"} value={listName} onChange={handleNameChange} />

          <div className='w-full'>
            <p className="text-white font-semibold text-md mb-2">Fecha de Compra</p>
            <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
              <input name="date" type="date" aria-autocomplete='none' list="autocompleteOff" className="[color-scheme:dark] appearance-none w-full h-full bg-transparent text-white color-sch placeholder:text-white px-4 placeholder:font-light rounded-xl" />
            </div>
          </div>
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleStore} disabled={isSubmitting}>Guardar Lista</PrimaryButton> {/* Deshabilitamos el botón */}
        </FooterToolbar>
      </div>
    </div>
  );
};

export default ShoppingListCreate;
