import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Input from './forms/Input';
import PrimaryButton from './ui/PrimaryButton';
import Textarea from './forms/Textarea';
import User from "../api/services/User.js";
import instagramIcon from '../icons/instagram.png';
import linkedinIcon from '../icons/linkedin.png';
import youtubeIcon from '../icons/youtube.png';
import { CameraIcon } from "@heroicons/react/24/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const UserProfile = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
        gender: 'Hombre',
        instagram: '',
        linkedin: '',
        youtube: ''
    });
    const [imageURL, setImageURL] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [imageData, setImageData] = useState(null);

    const [editingField, setEditingField] = useState(null);

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        try {
            const response = await User.profile();
            setProfile({
                name: response.data.name,
                email: response.data.email,
                phone: response.data.phone,
                description: response.data.description || '',
                gender: response.data.gender.name,
                instagram: response.data.instagram || '',
                linkedin: response.data.linkedin || '',
                youtube: response.data.youtube || ''
            });
            setImageURL(response.data.image_url);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const handleSaveProfile = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const genderId = profile.gender === 'Hombre' ? 1 : 2;
            const updatedProfile = { 
                ...profile,
                gender_id: genderId
            };
    
            const body = new FormData();
            for (const key in updatedProfile) {
                body.append(key, updatedProfile[key]);
            }
            
            if (imageFile) {
                body.append('image_file', imageFile);
            }

            console.log(body);
    
            await User.update(user.id, body);
            
            const updatedUser = { ...user, name: updatedProfile.name };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            navigate('/sidebarMenu');
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Ha ocurrido un error al editar');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile({
            ...profile,
            [name]: value
        });
    };

    const handleEditField = (field) => {
        setEditingField(field);
    };

    const renderSocialInputOrText = (name, placeholder, icon) => (
        <div className='w-full flex justify-start gap-3 bg-buddy-bg-2 p-3 rounded-2xl' style={{ alignItems: 'center' }} onClick={() => handleEditField(name)}>
            <img src={icon} alt={name} style={{ width: '24px', height: '24px' }} />
            {editingField === name ? (
                <Input 
                    name={name}
                    type='text'
                    placeholder={placeholder} 
                    value={profile[name] || ''}
                    onChange={handleChange}
                    onBlur={() => setEditingField(null)}
                />
            ) : (
                <div className='px-2 text-start'>
                    <span className='text-buddy-text-1 mt-auto mb-auto font-semibold'>
                        {profile[name] ?? ''}
                    </span>
                </div>
            )}
        </div>
    );

    const onChangeImageFile = (e) => {
        if (e.target.files[0]) {
            setImageFile(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onResetImageFile = e => {
        setImageFile(null);
        setImageData(null);
    };

    return (
        <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
            <div className='flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
                <Toolbar to={"/sidebarMenu"}>Edición de perfil</Toolbar>
                <div className='flex flex-col items-center gap-3 py-4'>
                    <Input name="name" type='text' placeholder='' label={"Nombre completo"} value={profile.name} onChange={handleChange} />
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="image_file"
                        onChange={onChangeImageFile}
                        className="hidden"
                    />

                    {imageFile || imageURL ? 
                        <div className="flex justify-center py-4">
                          <div className="relative flex justify-center items-center w-52 h-52">
                            <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4 overflow-hidden">
                              <img onClick={() => fileInputRef.current.click()} className="h-full w-full object-cover object-center" src={imageFile ? imageData : imageURL} />
                            </div>
                            {!imageURL &&
                            <button type='button' onClick={onResetImageFile} className='absolute -right-2 -top-2 flex items-center justify-center w-8'>
                              <div className='w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center'>
                                  <FontAwesomeIcon className='text-white' icon={faTimes} />
                              </div>
                            </button>
                            }
                          </div>
                        </div>
                        :
                        <div className="flex justify-center py-4">
                          <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4">
                            <button onClick={() => fileInputRef.current.click()} className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2">
                              <CameraIcon className="size-6" />
                            </button>
                          </div>
                        </div>
                    }
                    
                    <Input name="email" type='email' placeholder='' label={"Correo electrónico"} value={profile.email} disabled={true} />
                    <Input name="phone" type='text' placeholder='' label={"Número de teléfono"} value={profile.phone} onChange={handleChange} />
                    <Textarea name="description" label={"Biografía"} value={profile.description} onChange={handleChange} />
                    <h2 className='w-full text-left text-white text-sm font-medium'>Género</h2>
                    <div className='flex gap-3 w-full px-2'>
                        <div className="flex gap-2 text-white">
                            <input
                                type="radio"
                                name="gender"
                                value="Mujer"
                                checked={profile.gender === 'Mujer'}
                                onChange={handleChange}
                                className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
                            />
                            <label className="block text-sm mt-auto">Mujer</label>
                        </div>
                        <div className="flex gap-2 text-white">
                            <input
                                type="radio"
                                name="gender"
                                value="Hombre"
                                checked={profile.gender === 'Hombre'}
                                onChange={handleChange}
                                className="shrink-0 border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow"
                            />
                            <label className="block text-sm mt-auto">Hombre</label>
                        </div>
                    </div>
                    <h2 className='w-full text-left text-white text-sm font-medium mt-4'>Redes Sociales</h2>
                    <div className="w-full flex flex-col gap-2">
                        {renderSocialInputOrText('instagram', 'www.instagram.com', instagramIcon)}
                        {renderSocialInputOrText('linkedin', 'www.linkedin.com', linkedinIcon)}
                        {renderSocialInputOrText('youtube', 'www.youtube.com', youtubeIcon)}
                    </div>
                </div>
                <FooterToolbar>
                    <PrimaryButton type='button' onClick={handleSaveProfile}>
                      Guardar cambios
                    </PrimaryButton>
                </FooterToolbar>
            </div>
        </div>
    );
};

export default UserProfile;