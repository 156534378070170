import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Subscription from '../api/services/Subscription';
import { loadStripe } from '@stripe/stripe-js';
import subscriptionImage from '../img/subscription_image.png';

const Suscripcion = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [validUntil, setValidUntil] = useState(null);
  const [statusLoaded, setStatusLoaded] = useState(false);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await Subscription.status();
        if (response.valid_until) {
          setValidUntil(response.valid_until);
          setStatusLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        setValidUntil(null);
        setStatusLoaded(true);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const stripePromise = loadStripe('pk_test_51OX2IoErxmByqRHB2Ia0mySCGc3fc9OEip9Uzfcx3kl5FFjmJoZYGU1isHsZljj1sKBTA3tN0ssppw1K2E9y7fwP00Wv6r8T5v');

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubscription = async () => {
    if (!selectedOption) {
      alert("Debes seleccionar un plan para continuar");
      return;
    }
  
    const months = selectedOption === 'one-month' ? 1 : selectedOption === 'three-months' ? 3 : 12;

    const body = new FormData();
    body.append('months', months);
  
    try {
      const { id } = await Subscription.createCheckoutSession(body);
  
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: id,
      });
  
      if (error) {
        console.error(error);
      }
    } catch (error) {
      console.error("Error creando la sesión de pago:", error);
    }
  };

  const handleGoToIndex = (e) => {
    navigate('/');
  };

  if(statusLoaded==false){
    return null;
  }else{
    return (
      <div className='relative h-full bg-no-repeat bg-cover bg-center overflow-scroll'>
        <div className='h-full flex justify-between flex-col h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <Toolbar to={"/sidebarMenu"}>Elegir Plan de BuddyMarket</Toolbar>
            {statusLoaded && validUntil ? (
              <div style={{ backgroundColor: "#314650" }} className='flex flex-col bg-red items-center gap-3 py-8 px-4 mt-5 rounded-lg'>
                <img
                  style={{ width: '200px' }}
                  src={subscriptionImage}
                  alt='Password Changed'
                />
                <h2 style={{ fontSize: "30px" }} className='w-full text-center text-white text-xl font-semibold'>¡Enhorabuena!  Ya estás suscrito en BuddyMarket!</h2>
                <span className='text-buddy-text-2 mt-4 text-center grow-0'>Gracias por actualizar a la Versión BuddyMarket de pago.  Ahora estás listo para disfrutar de todos los fantásticos beneficios y características que lo acompañan. Si tienes alguna pregunta o necesitas ayuda, no dudes en comunicarte con nosotros. ¡Que disfrutes de la APP!</span>
              </div>
            ) : (
              <h2 className='w-full text-buddy-text-3 font-semibold mt-6'>
                No tienes una suscripción activa. Selecciona una opción para suscribirte:
              </h2>
            )}
            {statusLoaded && !validUntil && (
              <>
                <div onClick={() => handleSelect('one-month')} className='w-full cursor-pointer'>
                  <div className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${selectedOption === 'one-month' ? 'border-2 border-orange-500' : ''}`}>
                    <div className='grow px-2 text-start'>
                      <span className='text-primary font-semibold grow-0'>Subscripción de un mes</span>
                    </div>
                    <div className='grow px-2 text-start text-left'>
                      <span className='text-buddy-text-3 font-semibold grow-0'>7.99 € / mes</span>
                    </div>
                  </div>
                </div>
  
                <div onClick={() => handleSelect('three-months')} className='w-full cursor-pointer'>
                  <div className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${selectedOption === 'three-months' ? 'border-2 border-orange-500' : ''}`}>
                    <div className='grow px-2 text-start'>
                      <span className='text-primary font-semibold grow-0'>Subscripción de tres meses</span>
                    </div>
                    <div className='grow px-2 text-right'>
                      <span style={{ backgroundColor: "#FF8C00" }} className='py-1 px-2 rounded-md text-black font-semibold grow-0'>Ahorra 10%</span>
                    </div>
                    <div className='grow px-2 text-start'>
                      <span className='text-buddy-text-3 font-semibold grow-0'>18.89 € / mes</span>
                    </div>
                  </div>
                </div>
  
                <div onClick={() => handleSelect('one-year')} className='w-full cursor-pointer'>
                  <div className={`w-full flex flex-col gap-3 justify-between bg-buddy-bg-2 p-3 rounded-xl py-4 ${selectedOption === 'one-year' ? 'border-2 border-orange-500' : ''}`}>
                    <div className='grow px-2 text-start'>
                      <span className='text-primary font-semibold grow-0'>Subscripción de un año</span>
                    </div>
                    <div className='grow px-2 text-right'>
                      <span style={{ backgroundColor: "#FF8C00" }} className='py-1 px-2 rounded-md text-black font-semibold grow-0'>Ahorra 20%</span>
                    </div>
                    <div className='grow px-2 text-start'>
                    <span className='text-buddy-text-3 font-semibold grow-0'>66.89 € / mes</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!validUntil && (
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleSubscription}>Suscribirse</PrimaryButton>
            </FooterToolbar>
            )}
            {validUntil && (
            <FooterToolbar>
              <PrimaryButton type='button' onClick={handleGoToIndex}>Empezar a usar BuddyMarket</PrimaryButton>
            </FooterToolbar>
            )}
        </div>
      </div>
    );
  }
};

export default Suscripcion;